import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import type { Lightbox } from '../../types';

import {
  LightboxConnectionParam,
  SANTA_MEMBERS_APP_DEF_ID,
  PROFILE_PAGE_BOB_APP_DEF_ID,
  FOLLOWING_FOLLOWERS_MODAL_WIDGET_ID,
  FOLLOWING_FOLLOWERS_MODAL_WIDGET_OPTIONS,
} from '../../constants';
import {
  EDITOR_X_LIGHTBOX_LAYOUT,
  EDITOR_X_LIGHTBOX_WIDGET_LAYOUT,
} from '../../constants/editor-x';
import { addWidget } from '../editor-sdk-wrappers';

type CreateLightboxOptions = {
  width: number;
  height: number;
} & Lightbox;

const createLightbox = async (
  editorSDK: FlowEditorSDK,
  options: CreateLightboxOptions,
) => {
  const { appDefinitionId, page, pageId, widgetId, width, height } = options;

  const popupRef = await editorSDK.document.pages.popupPages.addConnected('', {
    title: page.name,
    shouldNavigateToPage: false,
    controllerType: `${LightboxConnectionParam.Controller}_${pageId}`,
    popupRole: `${LightboxConnectionParam.Role}_${pageId}`,
    definition: {
      data: {
        appDefinitionId,
        managingAppDefId: SANTA_MEMBERS_APP_DEF_ID,
        tpaPageId: pageId,
        hidePage: false,
        pageBackgrounds: {
          desktop: {
            custom: true,
            isPreset: false,
            ref: {
              type: 'BackgroundMedia',
              color: '#000',
              /** @ts-expect-error */
              colorOpacity: 0.6,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
            },
          },
          mobile: { custom: false, isPreset: false },
        },
      },
      components: [
        {
          type: 'Container',
          id: widgetId,
          skin: 'wysiwyg.viewer.skins.area.RectangleArea',
          layout: {
            width,
            height,
            x: 0,
            y: 0,
          },
          layouts: EDITOR_X_LIGHTBOX_LAYOUT,
          componentType: 'wysiwyg.viewer.components.PopupContainer',
          props: {
            type: 'PopupContainerProperties',
          },
        },
      ],
      style: {
        type: 'ComponentStyle',
        style: {
          properties: {
            'alpha-bg': '0.6',
            bg: 'color_15',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            bg: 'theme',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Page',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
        pageId: '',
        compId: '',
      },
    },
  });

  // editorSDK.document.pages.popupPages.updateBehavior('', {
  //   popupRef,
  //   behavior: {
  //     name: 'openPopup',
  //     params: {
  //       openInDesktop: false,
  //       openInMobile: false,
  //       delay: 2,
  //     },
  //   },
  // });

  const [lightboxCompRef] = await editorSDK.document.components.getChildren(
    '',
    { componentRef: popupRef },
  );

  console.log({ lightboxCompRef, popupRef });

  return lightboxCompRef ?? popupRef;
};

const lightboxWidgetOptionsMap: Record<
  string,
  { layout: { width: number; height: number } }
> = {
  [FOLLOWING_FOLLOWERS_MODAL_WIDGET_ID]:
    FOLLOWING_FOLLOWERS_MODAL_WIDGET_OPTIONS,
};

const installLightboxWidget = async (
  editorSDK: FlowEditorSDK,
  lightbox: Lightbox,
) => {
  const lightboxWidgetOptions = lightboxWidgetOptionsMap[lightbox.widgetId];
  if (!lightboxWidgetOptions) {
    return;
  }

  const lightboxCompRef = await createLightbox(editorSDK, {
    ...lightbox,
    width: lightboxWidgetOptions.layout.width,
    height: lightboxWidgetOptions.layout.height,
  });
  console.log({ lightboxCompRef });

  // return addWidget(editorSDK, {
  //   ...lightboxWidgetOptions,
  //   containerRef: lightboxCompRef,
  //   layouts: EDITOR_X_LIGHTBOX_WIDGET_LAYOUT,
  // });
};

export const installLightboxWidgets = async (
  editorSDK: FlowEditorSDK,
  lighboxes: Lightbox[],
) => {
  const promises = lighboxes.map((lightbox) => {
    return installLightboxWidget(editorSDK, lightbox);
  });

  await Promise.all(promises);
};
